<template>
  <div class="music-detail-container">
    <div class="title">
      <p>相似音乐推荐</p>
      <span>SIMILAR MUSIC</span>
    </div>
    <div v-loading="loading">
      <music-list :list="similarList" :isSimilar="false"></music-list>
    </div>

  </div>
</template>
<script>
import { fetchMusicSimilar } from "@/api/music";
import MusicList from '../components/MusicList.vue';
import Buttons from "@/layout/mixins/Buttons";

export default {
  name: "Similar",
  components: {
    MusicList
  },
  mixins: [Buttons],

  data(){
    return {
      loading: false,
      similarList: [],
    }
  },
  created(){
    this.getMusicList()
  },
  methods: {
    async getMusicList(){
      try {
        this.loading = true
          const { data } = await fetchMusicSimilar(this.$route.params.id);
          this.loading = false
          this.similarList = data
        } catch (e) {
          this.loading = false
          console.log(e);
        }
    },
  }
}
</script>

<style lang="scss" scoped>
$max-width: 1200px;
.center {
  width: $max-width;
  margin: 0 auto;
}
.music-detail-container {
  @extend .center;
  margin-top: 40px;
  padding-top: 70px;
  padding-bottom: 80px;
  min-height: calc(100vh - 268px);
  .title {
    text-align: center;
    margin-bottom: 36px;
    p{
      font-size: 36px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 8px;
    }
    span {
      font-size: 20px;
      color: #C3C3CD;
      letter-spacing: 1px;
    }
  }
}


</style>
